var prodcat = prodcat || {};

prodcat.data = prodcat.data || {};

(function ($, Drupal, prodcat) {
  var _wrapperClass = '.js-product-shade-picker-v2';
  var _isSoldOut = function (sku) {
    return !!sku.isSoldOut;
  };
  var _isShoppable = function (sku) {
    return !!sku.isShoppable;
  };

  Drupal.behaviors.productShadePickerV2 = {
    attach: function (context) {
      $('.js-product-shade-picker-v2-shade', $(_wrapperClass, context)).each(function () {
        var $shade = $(this);
        var skuId = $shade.data('sku');
        var sku;
        var classes = [];

        // @todo from greg we should return out of here if !skuId too
        if (skuId) {
          sku = prodcat.data.getSku(skuId);
          if (!sku) {
            return;
          }
        }

        if (_isSoldOut(sku)) {
          classes.push('inv-status--sold-out');
        }
        if (!_isShoppable(sku)) {
          classes.push('inv-status--not-shoppable');
        }

        if (classes.length > 0) {
          $shade.addClass(classes.join(' '));
        }
      });
    }
  };

  $(document).on('click', '.js-product-shade-picker-v2-shade', function (event) {
    event.preventDefault();

    var $picker = $(this);
    var skuId = $picker.data('sku');
    var skuData;
    var $product = $();

    if (skuId) {
      skuData = prodcat.data.getSku(skuId);
      if (skuData) {
        $product = $picker.closest('.js-product');
        $product.trigger('product.skuSelect', [skuData.SKU_BASE_ID]);
      }
    }
  });

  $(document).on('product.skuSelect', '.js-product', function (event, skuBaseId) {
    var selectedSku = prodcat.data.getSku(skuBaseId);
    var $shadeInfo = $('.js-product-shade-picker__shadeinfo', this);

    // @TODO: If there are multiple instances of this template on a page, won't this remove the active state from all of them?
    // @todo from greg
    // $('.js-product-shade-picker-v2-shade', this)
    //   .removeClass('shade-active');
    //   .filter('[data-sku="SKU' + skuBaseId + '"]')
    //   .addClass('shade-active');
    $('.js-product-shade-picker-v2-shade', this).removeClass('shade-active');

    $('.js-product-shade-picker-v2-shade[data-sku="SKU' + skuBaseId + '"]', this).addClass('shade-active');
    $shadeInfo.addClass('hidden');
    if (selectedSku && selectedSku.SHADE_DESCRIPTION) {
      $shadeInfo.removeClass('hidden');
      $('.js-product-shadename', this).html(selectedSku.SHADENAME);
      $('.js-product-shade-description', this).html(selectedSku.SHADE_DESCRIPTION);
      $('.product-shade-picker__description', this).removeClass('short-description');
    }
  });
})(jQuery, window.Drupal || {}, prodcat);
